
import { defineComponent, reactive, ref,computed,compile } from 'vue'
import httpRequest from '@/service'
import { useRoute, useRouter } from 'vue-router'
import BreadCrumb from '@/components/breadCrumb/index.vue'
import basicInfos from './cpns/basicInfos.vue'
import analyseCase from './cpns/analyseCase.vue'
import wdocument from './cpns/document.vue'
import recommend from './cpns/recommend.vue'
import { isCollect } from '@/utils/collect'
import { debounce } from 'throttle-debounce'
import { ElMessage } from 'element-plus/lib/components'
import PopoverMenu from '@/components/PopoverMenu/index.vue'
import usePopoverMenu from '@/utils/usePopoverMenu'
interface IObj {
  id: string

  [key: string]: any
}

interface IList {
  title: string
  data: string
  basicInfos: any
  getData: () => void
}

export default defineComponent({
  name: 'DetailCasePage',
  components: {
    BreadCrumb,
    basicInfos,
    analyseCase,
    wdocument,
    recommend,
    PopoverMenu
  },
  props: {
    detailData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  setup() {
    const { popShow, popLeft, popTop, showPop,handleCopy,handleSearch } = usePopoverMenu((value)=>{
      const routeData = router.resolve({ path: '/SearchLawList', query: { name:'标题',field:'searchTitle',value } })
      window.open(routeData.href, '_blank')
    })
    const loading = ref(true)
    const route = useRoute()
    const router = useRouter()

    // 字体大小切换
    const fontSizeRef = ref()
    const fontSizeState = reactive({
      curSize: 16, // 16-24
      sizeChange: (type: string) => {
        if (type === 'smaller' && fontSizeState.curSize < 18) {
          alert('已经缩小到最小')
          return
        }
        if (type === 'bigger' && fontSizeState.curSize > 22) {
          alert('已经放大到最大')
          return
        }
        fontSizeState.curSize =
          type === 'smaller'
            ? fontSizeState.curSize - 2
            : fontSizeState.curSize + 2
        fontSizeRef.value.style.fontSize = String(fontSizeState.curSize + 'px')
      }
    })

    // 背景颜色切换
    const skinState = reactive({
      data: ['back1', 'back2', 'back3'],
      data2: ['card1', 'card2', 'card3'],
      curSkin: 'back1',
      curSkin2: 'card1',
      change: (index: number) => {
        skinState.curSkin = skinState.data[index]
        skinState.curSkin2 = skinState.data2[index]
      }
    })

    //下载
    const dowmloadLink = ref('')
    // 收藏
    const isFavor = debounce(500, () => {
      isFavorValue.value = !isFavorValue.value
      isCollect(isFavorValue.value, currentID.value,4)
    })

    const isFavorValue = ref(false)
    const currentID = ref('')
    const currentName = ref('')
    const detailCasePageList = reactive<any>({
      title: '',
      data: '',
      basicInfos: [],
      getData() {
        return httpRequest
          .post({
            url: 'searchJudgeDetail',
            params: { id: route.query.id }
          })
          .then((res) => {
            const _this = detailCasePageList
            _this.basicInfos = res.data
            currentID.value = res.data.id
            if(_this.basicInfos.caseName){
              const title = document.getElementsByTagName('title')[0]
              title.innerHTML = _this.basicInfos.caseName
            }
            // 判断是否收藏
            httpRequest.post({
              url: 'judgeCollection4',
              params: { judgmentId: route.query.id }
            }).then(res2=>{
              isFavorValue.value = res2.data
            })
            loading.value = false
          })
          .catch((err) => {
            console.log(err)
          })
      },
      getLawList(){
        httpRequest
          .post({
            url: 'relateLaws',
            params: { id: route.query.id }
          })
          .then((res) => {
            res.data.forEach(item=>{
              detailCasePageList.basicInfos.richContent = detailCasePageList.basicInfos.richContent.replaceAll(item.lawName,'<span class="relate-link" data-id="'+item.lawVersionId+'">'+item.lawName+'</span>')
              detailCasePageList.basicInfos.content = detailCasePageList.basicInfos.content.replaceAll(item.lawName,'<span class="relate-link" data-id="'+item.lawVersionId+'">'+item.lawName+'</span>')
            })
          })
      }
    })
    detailCasePageList.getData().then(() => {
      detailCasePageList.getLawList()
    })
   
    
    const dynamicContent = computed(() => (content: string) => {
      if(!content) return;
      let dcontent = content.replaceAll(/(<span class="relate-link" data-id="([0-9]+?)">.*?<\/span>)/g, `
      <el-popover
        popper-class="hover-pop"
        :width="800"
        :show-arrow="false"
        placement="top"
        trigger="hover"
        @show="lawSummary.loadData('$2')">
        <template #reference>
          <span v-html='\`$1\`'></span>
        </template>
        <div
          class="hover-law-summary"
          v-loading="lawSummary.loading()"
          element-loading-background="rgba(0, 0, 0, 0)">
          <div class="law-summary-title" v-if="!lawSummary.loading()">
        <h3 @click="lawSummary.jump('$2')">
          {{ lawSummary.data().lawName || '法律标题' }}
        </h3>
          </div>
          <hr v-if="!lawSummary.loading()" />
          <div class="law-summary-properties" v-if="!lawSummary.loading()">
        <el-row>
          <el-col :span="14">
        <span class="law-summary-properties-label">发布机关</span>
        <span class="law-summary-properties-value">{{ lawSummary.data().publishOrgName }}</span>
          </el-col>
          <el-col :span="10">
        <span class="law-summary-properties-label">时效性</span>
        <span class="law-summary-properties-value">{{ lawSummary.data().timelinessName }}</span>
          </el-col>
          <el-col :span="14">
        <span class="law-summary-properties-label">发文字号</span>
        <span class="law-summary-properties-value">{{ lawSummary.data().lawNumber }}</span>
          </el-col>
          <el-col :span="10">
        <span class="law-summary-properties-label">效力级别</span>
        <span class="law-summary-properties-value">{{ lawSummary.data().effectLevelName }}</span>
          </el-col>
          <el-col :span="14">
        <span class="law-summary-properties-label">发布日期</span>
        <span class="law-summary-properties-value">{{ lawSummary.data().publishDate }}</span>
          </el-col>
          <el-col :span="10">
        <span class="law-summary-properties-label">实施日期</span>
        <span class="law-summary-properties-value">{{ lawSummary.data().implDate }}</span>
          </el-col>
        </el-row>
          </div>
        </div>
      </el-popover>
      `)
      return compile(dcontent)
    })
    // // 超链接
    const cacheObj: { [index: string]: any } = {}
    const lawSummary = reactive({
      currentId: '',
      data() {
        return this.cache['law_' + this.currentId] || {
          lawVersionId: undefined,
          lawName: undefined,
          publishOrgName: undefined,
          timelinessName: undefined,
          lawNumber: undefined,
          effectLevelName: undefined,
          publishDate: undefined,
          implDate: undefined
        }
      },
      cache: cacheObj,
      loading() {
        return !this.cache['law_' + this.currentId]
      },
      loadData(lawVersionId: string) {
        if (!lawVersionId) {
          return
        }
        this.currentId = lawVersionId
        if (this.cache['law_' + lawVersionId]) {
          return
        }
        httpRequest
          .get({
            url: 'lawSummary',
            params: {
              lawVersionId: lawVersionId
            }
          })
          .then((res: any) => {
            this.cache['law_' + lawVersionId] = res.data
          })
          .catch((err) => {
            ElMessage({
              message: '法律摘要加载失败',
              type: 'error'
            })
          })
      },
      jump(lawVersionId: string) {
        const { href } = router.resolve({
          name: 'DetailPage',
          query: {
            id: lawVersionId
          }
        })
        window.open(href, '_blank')
      }
    })
    return {
      loading,
      detailCasePageList,
      dowmloadLink,
      isFavor,
      isFavorValue,
      fontSizeRef,
      fontSizeState,
      skinState,
      currentID,
      currentName,
      dynamicContent,
      lawSummary,
      popShow, 
      popLeft, 
      popTop, 
      showPop,
      handleCopy,
      handleSearch,
      REQUIRE_LOGIN:process.env.VUE_APP_REQUIRE_LOGIN
    }
  }
})
